export const titles = {
  en: {
    home: 'Home',
    about: 'About us',
    contact: 'Contact',
  },
  pt: {
    home: 'Início',
    about: 'Sobre nós',
    contact: 'Contato',
  },
};
